import React, { useState, useEffect } from "react";
import * as G from "../style/GlobalStyle.style";
import * as A from "../style/Pages.style";
import * as S from "../style/Service.style";
import AOS from "aos";
import "aos/dist/aos.css";

import Edutech from "../asset/img/Slide/edu_nuki.png";
import Chaple from "../asset/img/HistoryChaple.png";
import ChapleLogo from "../asset/img/chaple_logo.png";
import IpsiMockup from "../asset/img/입시목업.png";
import IBE from "../asset/img/uchiown.png";

const Service = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <G.LayoutArea>
      <A.PageArea>
        <A.PageTopArea>
          <div class="wrap">
            <div class="inner">
              <A.PageLabel data-aos="fade-up" data-aos-duration="2000">
                SERVICE
              </A.PageLabel>
              <A.PageTitle data-aos="fade-up" data-aos-duration="2500">
                서비스
              </A.PageTitle>

              <S.Section>
                <div class="wrap">
                  <div class="inner">
                    <S.ServiceItem>
                      <div class="service-img-area">
                        <img
                          src={Edutech}
                          alt=""
                          data-aos="fade-up"
                          data-aos-duration="2500"
                          class="service-img"
                        />
                      </div>
                      <div class="service-text-area">
                        <div
                          class="service-title"
                          data-aos="fade-up"
                          data-aos-duration="2500"
                        >
                          통합형 학생관리시스템 EDUTECH365
                        </div>
                        <div
                          class="service-subtitle"
                          data-aos="fade-up"
                          data-aos-duration="2500"
                        >
                          복잡한 학교관리의 고민을 담다.
                        </div>
                        <div
                          class="service-text"
                          data-aos="fade-up"
                          data-aos-duration="2500"
                        >
                          EDUTECH365는 국내 최초로 교사, 학생, 학부모의 니즈가
                          통합되어 개발된 통합형 학생 관리 시스템입니다. 진보된
                          UI/UX 구성으로 모든 사용자의 편의성 증대를
                          구현하였습니다. <br />
                          또한, 모듈형 구조를 통한 개발로 어떤 사용처에서도
                          사용할 수 있는 무한한 확장성과 발전 가능성을 보유하고
                          있습니다.
                        </div>
                        <a
                          href="/Contact"
                          data-aos="fade-up"
                          data-aos-duration="2500"
                          class="service-gobutton"
                        >
                          우리 학교에서도 사용하고 싶어요!
                        </a>
                      </div>
                    </S.ServiceItem>
                    <S.ServiceItem>
                      <div class="service-img-area">
                        <img
                          src={IpsiMockup}
                          alt=""
                          data-aos="fade-up"
                          data-aos-duration="2500"
                          class="service-img"
                        />
                      </div>
                      <div class="service-text-area">
                        <div
                          class="service-title"
                          data-aos="fade-up"
                          data-aos-duration="2500"
                        >
                          고교 입시 통합 웹사이트
                        </div>
                        <div
                          class="service-subtitle"
                          data-aos="fade-up"
                          data-aos-duration="2500"
                        >
                          입시생 유입의 극대화를 이끌어내는{" "}
                          <span>입시 통합 웹사이트</span>
                        </div>
                        <div
                          class="service-text"
                          data-aos="fade-up"
                          data-aos-duration="2500"
                        >
                          익스팬드의 고교 입시 통합 웹사이트는 기존 웹사이트들이
                          개별적으로 제공하던 모집요강, 교과성적산출,
                          입시설명회, 학교 홍보자료 등의 정보를 통합해 향상된
                          효율성으로 입시생 유입의 극대화를 이끌어냅니다. <br />
                          <br />각 학교의 요구사항에 맞는 개별적인 디자인으로
                          다른 학교와의 차별점을 원하는 고교를 대상으로 최고의
                          만족감을 선사시켜드립니다.
                        </div>
                        <a
                          href="/Contact"
                          data-aos="fade-up"
                          data-aos-duration="2500"
                          class="service-gobutton"
                        >
                          우리 학교에서도 사용하고 싶어요!
                        </a>
                      </div>
                    </S.ServiceItem>
                    <S.ServiceItem>
                      <div class="service-img-area">
                        <img
                          src={IBE}
                          alt=""
                          data-aos="fade-up"
                          data-aos-duration="2500"
                          class="service-img"
                        />
                      </div>
                      <div class="service-text-area">
                        <div
                          class="service-title"
                          data-aos="fade-up"
                          data-aos-duration="2500"
                        >
                          IBE - 유치원 브랜딩 웹 솔루션
                        </div>
                        <div
                          class="service-subtitle"
                          data-aos="fade-up"
                          data-aos-duration="2500"
                        >
                          학부모를 위한, 예비 원생을 위한 유치원{" "}
                          <span>웹 솔루션</span>
                        </div>
                        <div
                          class="service-text"
                          data-aos="fade-up"
                          data-aos-duration="2500"
                        >
                          IBE(아이비)는 유치원만을 위한 맞춤형 웹사이트 개발
                          솔루션입니다.
                          <br />
                          단순한 홈페이지를 넘어, 유치원의 교육 철학과 가치를
                          효과적으로 <br />
                          전달할 수 있도록 설계되었습니다.
                          <br />
                          <br />
                          유치원 웹사이트에는 교육 목적 및 비전, 시설
                          둘러보기(360° 뷰어),
                          <br /> 공지사항 게시판 등 필요한 기능을 직관적이고
                          편리한 형태로 제공합니다.
                          <br />
                          <br />
                          IBE를 통해 유치원의 브랜드 가치를 높이고, 학부모와의
                          원활한 소통이 이루어지는 스마트한 교육 환경을
                          만들어보세요!
                        </div>
                        <a
                          href="/Contact"
                          data-aos="fade-up"
                          data-aos-duration="2500"
                          class="service-gobutton"
                        >
                          우리 유치원에서도 사용하고 싶어요!
                        </a>
                      </div>
                    </S.ServiceItem>
                    <S.ServiceItem>
                      <div class="service-img-area">
                        <img
                          src={Chaple}
                          alt=""
                          data-aos="fade-up"
                          data-aos-duration="2500"
                          class="service-img"
                        />
                      </div>
                      <div class="service-text-area">
                        <div class="service-title">
                          <img
                            src={ChapleLogo}
                            alt=""
                            class="service-title-img"
                          />
                        </div>
                        <div
                          class="service-title"
                          data-aos="fade-up"
                          data-aos-duration="2500"
                        >
                          K-POP 굿즈 거래 전문 플랫폼 <strong>CHAPLE</strong>
                        </div>
                        <div class="service-subtitle"></div>
                        <div
                          class="service-text"
                          data-aos="fade-up"
                          data-aos-duration="2500"
                        >
                          EXPAND의 "CHAPLE"은 K-POP 시장 성장에 맞춰 출시 예정인
                          굿즈 거래 전문 플랫폼입니다. <br />
                          주요 소비자인 K-POP 팬덤의 니즈를 집요하게 파고들어,
                          거래에서 발생하는 여러 문제에서 가장 완벽한 해결책을
                          제공합니다. "CHAPLE"은 검수거래, 경매 등의 제공해
                          기존의 중고 거래 플랫폼과의 확연한 차이점이
                          존재합니다. <br />
                          <br />
                          "CHAPLE"은 기획만으로 2023년 [생애최초청년지원사업]에
                          선정되어 5000만원의 투자 유치를 이끌어 냈으며 30여개의
                          창업 팀 가운데 3위, 최우수 졸업을 완료했습니다.
                        </div>
                      </div>
                    </S.ServiceItem>
                  </div>
                </div>
              </S.Section>
            </div>
          </div>
        </A.PageTopArea>
      </A.PageArea>
    </G.LayoutArea>
  );
};

export default Service;

import React, { useState, useEffect } from "react";
import * as G from "../style/GlobalStyle.style";

import SlideImage1 from "../asset/img/Slide/edu_nuki.png";
import chaple from "../asset/img/Slide/chaple.png";
import gbsw from "../asset/img/입시목업.png";
import IBE from "../asset/img/uchiown.png";
import ChapleLogo from "../asset/img/chaple_logo.png";
import { ReactComponent as ButtonIcon } from "../asset/icon/arrow.svg";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [timer, setTimer] = useState(null); // To keep track of the interval

  const slides = [
    {
      img: SlideImage1,
      title: "EDUTECH365 통합형 학생관리서비스",
      text: (
        <>
          모듈형 웹앱 / 웹사이트를 통해 경제성, 효율성, 편리성을 모두 갖춘
          학생관리서비스입니다.
          <br />
          SW를 통한 교육을 성장을 느껴보세요.
        </>
      ),
      link: "/Product",
      buttonText: "EDUTECH 바로가기",
    },
    {
      img: gbsw,
      title: "고교 입시 웹사이트",
      text: (
        <>
          은근히 번거로운 고교 입시 준비, 웹사이트 하나로 해결!
          <br />
          학교소개, 입시정보, 공지사항, 각종 활동 참여 신청, 합격자조회와
          관리자페이지까지!
          <br />
          <br />
          익스팬드 입시사이트로 입시의 고난을 헤쳐나가세요!
        </>
      ),
      link: "/Product",
      buttonText: "고교입시 웹사이트 바로가기",
    },
    {
      img: chaple,
      title: <img src={ChapleLogo} alt="Chaple Logo" />,
      subtitle: "굿즈 중고거래 플랫폼 채플",
      text: (
        <>
          한번도 경험해보지 못한 굿즈거래의 신세계 채플
          <br />
          안전한 굿즈거래, 혁신적인 카테고리 시스템,
          <br />
          판매자 친화적 기능에 이벤트까지!
          <br />
          <br />
          채플은 여러분이 한번도 경험해보지 못한 신세계로 초대합니다.
        </>
      ),
      link: "/Product",
      buttonText: "CHAPLE 바로가기",
    },

    {
      img: IBE,
      title: "유치원 브랜딩 웹 솔루션 - 아이비(IBE)",
      text: (
        <>
          유치원생 학부모들을 위한 서비스, 아이비
          <br />
          내 아이의 첫걸음. 유치원은 잘 골라야 합니다.
          <br />
          유치원 정보, 시설, 교육목적 등을 한번에 볼 수 있습니다.
          <br />
          <br />
          아이를 위해 쏟는 노력을 더 편리하고 간결하게!
        </>
      ),
      link: "http://barbeta.dothome.co.kr/index.html",
      buttonText: "예시 웹사이트 바로가기",
    },
  ];

  const resetTimer = () => {
    if (timer) clearInterval(timer);
    const newTimer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);
    setTimer(newTimer);
  };

  // Initialize the timer on component mount
  useEffect(() => {
    resetTimer();
    return () => clearInterval(timer); // Clear interval on component unmount
  }, []);

  // Handlers for Previous and Next buttons with timer reset
  const handlePrevious = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
    resetTimer();
  };

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
    resetTimer();
  };

  return (
    <G.Slider>
      <div className="slide-outer">
        <div className="slide-controls">
          <button onClick={handlePrevious} className="previous-button">
            <ButtonIcon />
          </button>
          <button onClick={handleNext} className="next-button">
            <ButtonIcon />
          </button>
        </div>
        <div className="slide-inner">
          {slides.map((slide, index) => (
            <G.SlideItem
              key={index}
              className={currentSlide === index ? "show" : "no-show"}
            >
              <div className="slideItem-img">
                <img src={slide.img} alt="" />
              </div>
              <div className="slideItem-text">
                <div className="slideItem-text-title">{slide.title}</div>
                {slide.subtitle && (
                  <div className="slideItem-text-title">{slide.subtitle}</div>
                )}
                <div className="slideItem-text-text">{slide.text}</div>
                <a href={slide.link} className="slideItem-text-button">
                  {slide.buttonText}
                </a>
              </div>
            </G.SlideItem>
          ))}
        </div>
      </div>
    </G.Slider>
  );
};

export default Slider;

import React, { useState, useEffect } from "react";
import * as G from "../style/GlobalStyle.style";
import * as A from "../style/Pages.style";
import * as S from "../style/Company.style";
import AOS from "aos";
import "aos/dist/aos.css";

import bg1 from "../asset/img/Company-bg-first.png";
import bg2 from "../asset/img/Company-bg-second.png";
import Logo from "../asset/img/logo.png";
import Map from "../component/KakaoMap";
const Company = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <G.LayoutArea>
      <A.PageArea>
        <A.PageTopArea>
          <div class="wrap">
            <div class="inner">
              <A.PageLabel data-aos="fade-up" data-aos-duration="2000">
                COMPANY
              </A.PageLabel>
              <A.PageTitle data-aos="fade-up" data-aos-duration="2500">
                기업소개
              </A.PageTitle>
            </div>
          </div>
        </A.PageTopArea>
        <S.Section>
          <div class="wrap">
            <div class="inner">
              <img
                src={bg1}
                alt=""
                class="section-bg"
                data-aos="fade-up"
                data-aos-duration="2000"
              />

              <div class="company-in-area">
                <img
                  src={Logo}
                  alt=""
                  class="company-logo"
                  data-aos="fade-up"
                  data-aos-duration="2500"
                />
                <div
                  class="company-first-section-text"
                  data-aos="fade-up"
                  data-aos-duration="2800"
                >
                  익스팬드의 발자취는 대표자 스스로의 불편함을 해결하기 위해
                  시작되었습니다.
                  <br />
                  <br />
                  현재 대구·경북 지역의 약 400개 중·고등학교에서는 여전히
                  불편하고 비효율적인 행정 시스템을 사용하고 있습니다.
                  <br /> 저희는 혁신적인 차세대 학교 운영·관리 플랫폼을 통해
                  교육 현장의 변화를 이끌어가고자 합니다.
                  <br /> 단순히 편리한 플랫폼을 개발하는 것을 넘어, 기존
                  시스템에서 낭비되던 행정적 리소스를 절감하고,
                  <br /> 학교 운영의 효율성을 높이며, 궁극적으로는 국가 인재
                  양성의 기반을 더욱 탄탄하게 다지는 것이 우리의 목표입니다.
                  <br />
                  21세기 글로벌 인재 육성에 기여하는 차세대 교육 혁신 플랫폼,
                  저희가 함께 만들어가겠습니다.
                </div>
              </div>
            </div>
          </div>
        </S.Section>

        <S.Section id="company-section-second">
          <div class="wrap">
            <div class="inner">
              <Map />

              <div class="company-information">
                <div class="company-information-line">
                  <div
                    class="comp-info-label"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    Address
                  </div>
                  <div
                    class="comp-info-value"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    대구광역시 수성구 달구벌대로528길 15 수성대학교 본관
                  </div>
                </div>
                <div class="company-information-line">
                  <div
                    class="comp-info-label"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    CEO
                  </div>
                  <div
                    class="comp-info-value"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    김창환
                  </div>
                  <div
                    class="comp-info-label"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    TEL
                  </div>
                  <div
                    class="comp-info-value"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    010-5306-0074
                  </div>

                  <div
                    class="comp-info-label"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    Employees
                  </div>
                  <div
                    class="comp-info-value"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    2 people
                  </div>
                </div>
                <div class="company-information-line">
                  <div
                    class="comp-info-label"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    E-Mail
                  </div>
                  <div
                    class="comp-info-value"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    imnews0772@daum.net
                  </div>
                </div>
              </div>
            </div>
          </div>
        </S.Section>

        <S.Section id="company-section-third">
          <div class="wrap">
            <div class="inner">
              <img
                src={bg2}
                alt=""
                class="section-bg-2"
                data-aos="fade-up"
                data-aos-duration="2000"
              />

              <div class="company-third-textArea">
                <div
                  class="company-third-title"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  COMPANY VISION
                </div>
                <div
                  class="company-third-subtitle"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  미래 교육의 혁신을 선도하는 회사
                </div>
                <div
                  class="company-third-text"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  저희는 학교 운영의 디지털 혁신을 통해 교육의 새로운 기준을
                  만들어갑니다.
                  <br />
                  비효율적인 행정 업무를 최소화하고, 교육 본연의 가치에 집중할
                  수 있는 환경을 조성하여
                  <br />
                  미래 인재를 키우는 탄탄한 기반을 마련하겠습니다.
                  <br />
                  <br />
                  기술을 통해 보다 스마트한 학교, 더 나은 교육 환경을 구축하며
                  <br />
                  21세기 글로벌 인재 양성에 앞장서는 교육 혁신 기업이
                  되겠습니다.
                </div>
              </div>
            </div>
          </div>
        </S.Section>
      </A.PageArea>
    </G.LayoutArea>
  );
};

export default Company;

import styled from "styled-components";
export const GlobalStyle = styled.div`
  @font-face {
    font-family: "Pretendard-Regular";
    src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
      format("woff");
    font-weight: 400;
    font-style: normal;
  }
  * {
    font-family: "Pretendard-Regular";
  }

  width: 100vw;
  margin: 0px auto;
  overflow-x: hidden;
  padding: 0px;
  font-family: "Pretendard-Regular";

  /*color*/
  --black: #000;
  --white: #fff;
  --adpoint: #5a67ff;
  --strong-gray: #878789;
  --gray: #f6f6f6;
  --text-gray: #878787;
  --red: #ff2b57;
  --blue: #0098fa;
  --orange: #ff4900;
  --point: #1f1f1f;
  --green: #00c6bd;
  --skyblue: #79e0ff;
  --click: rgba(255, 255, 255, 0.4);
  --form: #f7f7f7;
  --yellow: #f39b4a;

  .wrap {
    width: 100%;
    position: relative;
  }

  .inner {
    width: calc(100vw - 400px);
    max-width: 1720px;
    margin: auto;
    position: relative;
  }
  .mobile-show {
    display: none;
  }
  a {
    text-decoration: none;
    color: var(--black);
  }

  .hidden {
    width: 0px !important;
    height: 0px !important;
    display: none !important;
    overflow: hidden !important;
    opacity: 0 !important;
    position: absolute !important;
    border: none !important;
    outline: none !important;
  }

  @media (max-width: 1600px) {
    .inner {
      width: calc(100vw - 160px);
    }
  }

  @media (max-width: 980px) {
    .inner {
      width: 90vw;
    }

    .wrap {
      width: 100vw;
    }

    .mobile-show {
      display: block;
    }
  }
`;

export default GlobalStyle;

export const LayoutArea = styled.div`
  width: 100%;
  position: relative;
  background-color: var(--black);
`;

export const Header = styled.div`
  width: 100%;
  height: 80px;
  position: fixed;
  z-index: 9999;
  background-color: var(--black);
  .inner {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-link-area {
    display: flex;
    align-items: center;
    gap: 80px;
  }

  .header-link {
    color: var(--white);
    font-size: 16px;
  }
  .header-left img {
    width: 120px;
  }
  .header-left,
  .header-right {
    width: 120px;
  }

  .header-right {
    display: flex;
    justify-content: right;
  }

  @media (max-width: 980px) {
    .header-right a {
      display: none;
    }

    .header-link-area {
      position: fixed;
      top: 80px;
      left: 0px;
      width: 100%;
      display: none;

      &.opened {
        display: block;
      }
      a {
        display: block;
        width: calc(100% - 24px);
        background-color: rgba(0, 0, 0, 0.8);

        padding: 5vw;
      }
    }

    .header-toggle-button {
      svg {
        width: 28px;
        height: 28px;

        path {
          fill: var(--white);
        }

        rect {
          fill: var(--white);
        }
      }
    }
  }
`;

export const Footer = styled.div`
  width: 100%;

  background-color: var(--black);
  padding: 30px 0px;
  .footer-top {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    padding-bottom: 24px;
    .footer-logo {
      width: 120px;
    }
  }

  .footer-bottom {
    padding-top: 24px;

    .footer-text-line {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 12px;
    }
    .footer-item {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .footer-item-label {
      color: var(--white);
      font-size: 16px;
    }

    .footer-item-value {
      color: rgba(255, 255, 255, 0.6);
    }

    .footer-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .footer-copyright {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  @media (max-width: 980px) {
    .footer-text-line {
      display: block !important;
      font-size: 10px;
      .footer-item {
        margin-bottom: 12px;
      }
      .footer-item-label {
        font-size: 10px;
      }
    }

    .footer-flex {
      display: block !important;
    }

    .footer-copyright {
      text-align: center;
      font-size: 10px !important;
      margin-top: 20px !important;
    }
  }
`;

export const Slider = styled.div`
  width: 100%;

  .slide-controls {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 10;
    justify-content: space-between;
    button {
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.3);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      border: none;
      outline: none;
      &:hover {
        background-color: rgba(255, 255, 255, 0.8);
      }
      svg {
        width: 60px;
        height: 60px;

        path {
          fill: var(--black);
        }
      }
    }
    button:first-child {
      margin-left: -120px;
    }
    button:last-child {
      margin-right: -120px;

      svg {
        transform: rotateZ(180deg);
      }
    }
  }
  .slide-outer {
    position: relative;
    height: 920px;
  }
  .slide-inner {
    height: 920px;
    position: absolute;
    width: 100%;
    top: 0px;
  }
  .slide-inner > div:last-child {
    img {
      width: 90%;

      filter: brightness(0.9);
    }
  }

  @media (max-width: 980px) {
    .slide-outer {
      height: 100vh;
    }
    .slide-inner {
      height: 100vh;
    }

    .slide-controls {
      display: none;
    }
  }
`;

export const SlideItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  z-index: 11;
  height: 920px;
  position: absolute;
  transition: all 0.5s ease;
  left: 0px;
  top: 0px;
  &.show {
    opacity: 1;
  }
  &.no-show {
    opacity: 0;
  }

  .slideItem-img {
    width: 50%;
    img {
      width: 100%;
    }
  }

  .slideItem-text {
    width: calc(40% - 20px);

    .slideItem-text-title {
      font-size: 42px;
      word-break: keep-all;
      font-weight: bold;
      color: var(--white);

      img {
        height: 60px;
        filter: brightness(0) invert();
      }
    }

    .slideItem-text-text {
      font-size: 24px;
      margin-top: 24px;
      word-break: keep-all;
      color: var(--white);
    }

    .slideItem-text-button {
      background-color: transparent;
      color: var(--blue);
      border: 1px solid var(--blue);
      font-size: 16px;
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 10px;
      margin-top: 40px;
      display: inline-block;
    }

    .slideItem-text-button:hover {
      background-color: var(--blue);
      color: var(--black);
    }
  }

  @media (max-width: 980px) {
    display: block;
    height: auto;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    .slideItem-img,
    .slideItem-text {
      width: 100%;

      img {
        width: 100% !important;
        margin-bottom: 30px;
      }
    }

    .slideItem-text-title {
      font-size: 20px !important;
      img {
        width: 150px !important;
        height: auto !important;
        margin-bottom: 12px !important;
      }
    }
    .slideItem-text-text {
      font-size: 14px !important;
      margin-top: 12px !important;
    }

    .slideItem-text-button {
      padding: 8px 16px !important;
      font-size: 14px !important;
      border-radius: 5px !important;
      margin-top: 24px !important;
    }
  }
`;

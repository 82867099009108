import styled from "styled-components";
export const Section = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const ServiceItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 50px;
  margin-bottom: 90px;
  .service-img-area {
    width: 50%;
    img {
      width: 100%;
      border-radius: 20px;
      height: auto;
      object-fit: cover;
    }
  }

  .service-text-area {
    width: calc(50% - 50px);

    .service-title {
      font-size: 42px;
      font-weight: bold;
      color: var(--white);
      word-break: keep-all;
      margin-bottom: 12px;

      img {
        width: 320px;
      }

      strong {
        color: #f16c87;
      }
    }

    .service-subtitle {
      word-break: keep-all;
      font-size: 28px;
      color: var(--white);
      margin-bottom: 24px;
      span {
        color: var(--blue);
        font-weight: bold;
      }
    }

    .service-text {
      word-break: keep-all;
      font-size: 20px;
      color: var(--white);
    }
    .service-gobutton {
      background-color: transparent;
      color: var(--blue);
      border: 1px solid var(--blue);
      font-size: 16px;
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 15px;
      margin-top: 40px;
      display: inline-block;
      &:hover {
        background-color: var(--blue);
        color: var(--black);
      }
    }
  }

  @media (max-width: 980px) {
    display: block;

    .service-img-area {
      width: 100%;

      img {
        height: auto;
        margin-bottom: 24px;
      }
    }
    .service-text-area {
      width: 100%;
    }

    .service-title {
      font-size: 24px !important;

      img {
        width: 160px !important;
      }
    }
    .service-subtitle {
      font-size: 20px !important;
      margin-bottom: 8px !important;
    }
    .service-text {
      font-size: 14px !important;
      br {
        display: none !important;
      }
    }
    .service-gobutton {
      padding: 8px 16px !important;
      font-size: 14px !important;
      border-radius: 5px !important;
      margin-top: 24px !important;
    }
  }
`;

import styled from "styled-components";
export const Section = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;

  .history-bg {
    width: 100%;
    height: calc(100%);
    position: absolute;

    border-radius: 20px;
    object-fit: cover;
    border-top-left-radius: 50px;
  }

  .wrap {
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0px;
  }

  .history-line {
    display: flex;
    align-items: start;

    .history-left,
    .history-right {
      box-sizing: border-box;
      width: 50%;
    }
    .history-left {
      padding: 0px 30px;
    }
    .history-right {
      border-left: 2px solid var(--white);
      padding-left: 24px;
    }
  }

  .history-right-itemList {
    padding-top: 60px;
    &:first-child {
      padding-top: 0px;
    }

    &:last-child {
      padding-bottom: 100px;
    }
  }

  .history-logo-img {
    width: 240px;
    display: block;
    margin: auto;
    margin-top: 50px;
  }

  @media (max-width: 980px) {
    .history-right {
      width: calc(100% - 30px) !important;
      padding-left: 30px;
    }
    .history-left {
      display: none;
    }
  }
`;

export const HistoryYear = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 30px;
  .history-year-text {
    font-size: 28px;
    margin-left: -12px;
    font-weight: bold;
    color: var(--white);
  }
  .history-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    transform: translateX(-45px);

    .history-circle-inner {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: var(--blue);
    }
  }

  @media (max-width: 980px) {
    margin-bottom: 16px !important;
    .history-circle {
      width: 20px !important;
      transform: translateX(-35px);
      height: 20px !important;
    }

    .history-circle-inner {
      width: 14px !important;
      height: 14px !important;
    }

    .history-year-text {
      font-size: 20px;
    }
  }
`;

export const HistoryItem = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
  padding-left: 30px;

  .history-item-label {
    font-size: 20px;
    font-weight: bold;
    color: var(--white);
  }

  .history-item-value {
    font-size: 20px;
    color: var(--white);
  }

  @media (max-width: 980px) {
    padding-left: 8px;
    gap: 0px;
    align-items: start;
    margin-bottom: 12px;
    .history-item-label {
      font-size: 14px;
      width: 40px;
    }
    .history-item-value {
      font-size: 14px;
    }
  }
`;

export const ImgLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 300px;

  &:first-child {
    padding-top: 72px;
  }
  &.topPadding2 {
    padding-top: 84px;
  }
  img {
    height: auto;
    display: block;
    object-fit: cover;
    opacity: 0.8;
    cursor: pointer;
  }
  .height-200 {
    height: 200px;
  }
  .height-350 {
    height: 350px;
  }
  img:hover {
    opacity: 1;
  }

  .half-img {
    width: calc(50% - 10px);
    border-radius: 10px;
  }
  .thirty-img {
    width: calc((100% - 40px) / 3);
    border-radius: 10px;
  }
  .fullsize-img {
    border-radius: 10px;
    width: calc(100%);
  }

  @media (max-width: 980px) {
    display: none !important;
  }
`;

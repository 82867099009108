import React, { useState, useEffect } from "react";
import * as G from "../style/GlobalStyle.style";
import * as A from "../style/Pages.style";
import * as S from "../style/History.style";
import AOS from "aos";
import "aos/dist/aos.css";

import Bg from "../asset/img/history-bg.jpg";
import Img20242 from "../asset/img/2024-2.jfif";
import Edu from "../asset/img/Slide/edu.png";
import Logo from "../asset/img/logo.png";
import Kdb from "../asset/img/kdb.jpg";
import Chaple from "../asset/img/HistoryChaple.png";
import Busi from "../asset/img/생창사.jpg";
import Chaple2 from "../asset/img/chaple2.png";
import History2 from "../asset/img/HistoryImg.jfif";
import Img2023 from "../asset/img/Img2023.jpg";
import Img20232 from "../asset/img/Img20232.jpg";
import Junction from "../asset/img/history-junction.jpg";
import Japan from "../asset/img/history-japan.jpg";

const History = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <G.LayoutArea>
      <A.PageArea>
        <A.PageTopArea>
          <div class="wrap">
            <div class="inner">
              <A.PageLabel data-aos="fade-up" data-aos-duration="2000">
                HISTORY
              </A.PageLabel>
              <A.PageTitle data-aos="fade-up" data-aos-duration="2500">
                연혁
              </A.PageTitle>

              <S.Section>
                <div class="wrap" data-aos="fade-up" data-aos-duration="2500">
                  <div class="inner">
                    <div class="history-line">
                      <div class="history-left">
                        <S.ImgLine>
                          <img
                            src={Kdb}
                            alt=""
                            class="thirty-img"
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          />
                          <img
                            src={Chaple}
                            alt=""
                            class="thirty-img"
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          />
                          <img
                            src={Busi}
                            alt=""
                            class="thirty-img"
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          />
                          <img
                            src={Edu}
                            alt=""
                            class="half-img height-200"
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          />
                          <img
                            src={Junction}
                            alt=""
                            class="half-img height-200"
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          />
                        </S.ImgLine>
                        <S.ImgLine>
                          <img
                            src={Kdb}
                            alt=""
                            class="thirty-img"
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          />
                          <img
                            src={Chaple}
                            alt=""
                            class="thirty-img"
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          />
                          <img
                            src={Busi}
                            alt=""
                            class="thirty-img"
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          />
                          <img
                            src={Japan}
                            alt=""
                            class="half-img height-200"
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          />
                          <img
                            src={History2}
                            alt=""
                            class="half-img height-200"
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          />
                        </S.ImgLine>

                        <S.ImgLine className="topPadding2">
                          <img
                            src={Img2023}
                            alt=""
                            class="half-img height-200"
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          />
                          <img
                            src={Img20232}
                            alt=""
                            class="half-img height-200"
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          />
                        </S.ImgLine>
                      </div>
                      <div class="history-right">
                        <div class="history-right-itemList">
                          <S.HistoryYear>
                            <div class="history-circle">
                              <div class="history-circle-inner"></div>
                            </div>

                            <p
                              class="history-year-text"
                              data-aos="fade-up"
                              data-aos-duration="2500"
                            >
                              2025
                            </p>
                          </S.HistoryYear>
                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">02월</label>
                            <p class="history-item-value">
                              유치원 브랜딩 웹 솔루션 : 아이비(IBE) 출시
                            </p>
                          </S.HistoryItem>
                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">01월</label>
                            <p class="history-item-value">
                              솔루션 자회사 아발론 런칭
                            </p>
                          </S.HistoryItem>
                        </div>

                        <div class="history-right-itemList">
                          <S.HistoryYear>
                            <div class="history-circle">
                              <div class="history-circle-inner"></div>
                            </div>

                            <p
                              class="history-year-text"
                              data-aos="fade-up"
                              data-aos-duration="2500"
                            >
                              2024
                            </p>
                          </S.HistoryYear>
                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">12월</label>
                            <p class="history-item-value">흑자 전환 성공</p>
                          </S.HistoryItem>
                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">11월</label>
                            <p class="history-item-value">
                              2024 청소년창업경진대회 강연
                            </p>
                          </S.HistoryItem>
                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">09월</label>
                            <p class="history-item-value">
                              학생관리서비스 EDUTECH 365 출시
                            </p>
                          </S.HistoryItem>
                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">08월</label>
                            <p class="history-item-value">
                              JUNCTION-ASIA 파트너쉽 부스 참여
                            </p>
                          </S.HistoryItem>
                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">05월</label>
                            <p class="history-item-value">
                              대구광역시 수성구 사무실 이전
                            </p>
                          </S.HistoryItem>

                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">03월</label>
                            <p class="history-item-value">
                              일반과세사업자 변경
                            </p>
                          </S.HistoryItem>

                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">02월</label>
                            <p class="history-item-value">
                              대구광역시 북구 사무실 이전
                            </p>
                          </S.HistoryItem>

                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">02월</label>
                            <p class="history-item-value">
                              생애최초청년창업지원사업 최우수 졸업
                            </p>
                          </S.HistoryItem>
                        </div>

                        <div class="history-right-itemList">
                          <S.HistoryYear>
                            <div class="history-circle">
                              <div class="history-circle-inner"></div>
                            </div>

                            <p
                              class="history-year-text"
                              data-aos="fade-up"
                              data-aos-duration="2500"
                            >
                              2023
                            </p>
                          </S.HistoryYear>
                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">11월</label>
                            <p class="history-item-value">
                              CHAPLE 프로토타입 완성
                            </p>
                          </S.HistoryItem>
                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">11월</label>
                            <p class="history-item-value">
                              KDB START UP 2024 고등부분 최우수 졸업
                            </p>
                          </S.HistoryItem>
                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">10월</label>
                            <p class="history-item-value">
                              엑스비스페이스 시범운영 실시
                            </p>
                          </S.HistoryItem>
                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">09월</label>
                            <p class="history-item-value">
                              Smarteen App-Challenge 수상
                            </p>
                          </S.HistoryItem>

                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">06월</label>
                            <p class="history-item-value">
                              정부지원금 50,000,000원 유치
                            </p>
                          </S.HistoryItem>

                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">06월</label>
                            <p class="history-item-value">
                              한-안세안 포럼 Meet-UP Day 참여
                            </p>
                          </S.HistoryItem>

                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">05월</label>
                            <p class="history-item-value">
                              생애최초청년창업지원사업 선정
                            </p>
                          </S.HistoryItem>

                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">03월</label>
                            <p class="history-item-value">
                              일본 후쿠오카 Growth Next 스타트업 연수 수료
                            </p>
                          </S.HistoryItem>
                        </div>

                        <div class="history-right-itemList">
                          <S.HistoryYear>
                            <div class="history-circle">
                              <div class="history-circle-inner"></div>
                            </div>

                            <p
                              class="history-year-text"
                              data-aos="fade-up"
                              data-aos-duration="2500"
                            >
                              2022
                            </p>
                          </S.HistoryYear>

                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">12월</label>
                            <p class="history-item-value">
                              지역창업아이디어 경진대회 수상
                            </p>
                          </S.HistoryItem>

                          <S.HistoryItem
                            data-aos="fade-up"
                            data-aos-duration="2500"
                          >
                            <label className="history-item-label">09월</label>
                            <p class="history-item-value">
                              경북소프트웨어고등학교 창업동아리 [익스팬드] 개설
                            </p>
                          </S.HistoryItem>
                        </div>
                      </div>
                    </div>

                    <img src={Logo} alt="" class="history-logo-img" />
                  </div>
                </div>
              </S.Section>
            </div>
          </div>
        </A.PageTopArea>
      </A.PageArea>
    </G.LayoutArea>
  );
};

export default History;

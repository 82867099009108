import React from "react";
import * as G from "../style/GlobalStyle.style";

import Logo from "../asset/img/logo.png";

const Footer = () => {
  return (
    <G.Footer>
      <div class="wrap">
        <div class="inner">
          <div class="footer-top">
            <div class="footer-top-left">
              <img src={Logo} alt="" class="footer-logo" />
            </div>
          </div>
          <div class="footer-bottom">
            <div class="footer-text">
              <div class="footer-text-line">
                <div class="footer-item">
                  <div class="footer-item-label">CEO</div>
                  <div class="footer-item-value">김창환</div>
                </div>

                <div class="footer-item">
                  <div class="footer-item-label">Company</div>
                  <div class="footer-item-value">
                    대구광역시 수성구 달구벌대로528길 15 수성대학교 본관 406호
                  </div>
                </div>
              </div>
              <div class="footer-flex">
                <div class="footer-text-line">
                  <div class="footer-item">
                    <div class="footer-item-label">TEL</div>
                    <div class="footer-item-value">010-5306-0074</div>
                  </div>

                  <div class="footer-item">
                    <div class="footer-item-label">E-Mail</div>
                    <div class="footer-item-value">imnews0772@daum.net</div>
                  </div>
                </div>

                <div class="footer-copyright">
                  © 2024. EXPAND Co. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </G.Footer>
  );
};

export default Footer;
